input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    width: 40px; 
    height: 40px;
    text-align: center;
    font-size: "1.2rem";
    margin: "0 5px";
    border-radius: 3px;
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
}

.otp:focus {
    box-shadow: none !important;
    outline: none !important;
}