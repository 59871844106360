.image-cropper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crop-div {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.post-img {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    text-align: center;
    align-items: center;
}

.image-upload-label {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;
}

.image-upload-input {
    display: none;
}

.image-upload-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 0.8rem 1.2rem;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.image-upload-button:hover {
    background-color: #3e8e41;
}

.img-display-before {
    height: 100px;
    width: 100px;
    /* border-radius: 100%; */
}

.img-display-after {
    height: 100px;
    width: 100px;
    /* border-radius: 100%; */
}

.image-croppers {
    display: none;
}